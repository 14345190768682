// アニメーション
new WOW().init();

// ページ内リンク
$(function () {
 var headerHight = 60; //ヘッダの高さ
 $('a[href^=#]').click(function(){
     var href= $(this).attr("href");
       var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top-headerHight; //ヘッダの高さ分位置をずらす
     $("html, body").animate({scrollTop:position}, 550, "swing");
        return false;
   });
});

// ハンバーガー
$(function() {
  var hNav = $('#h_nav');
  var drawerBtn = $('#h_toggle_btn');
  $(document).on('click', '#h_toggle_btn', function() {
    if ($('#h_toggle_btn').hasClass('open')) {
      $('#h_toggle_btn').removeClass('open');
      $('#h_nav').removeClass('on');
      $('body').removeClass('fixed').css({'top': 0});
      window.scrollTo(0, pos);
    } else {
      $('#h_toggle_btn').addClass('open');
      $('#h_nav').addClass('on');
      pos = $(window).scrollTop();
      $('body').addClass('fixed').css({'top': -pos});
      state = true;
    }
  });
});

$(document).ready(function() {
  $('#pc_nav').superfish();
});

$(function(){
  var h_fixed=$('#h_cover');
  $(window).on('load scroll',function(){
    if($(this).scrollTop()>300){
      h_fixed.addClass('on')
    }else{
      h_fixed.removeClass('on')
    }
  })
});


$(function(){
  $('.faq_list dt').on('click', function() {
    $(this).next().slideToggle();
    if($(this).hasClass('active')){
      $(this).removeClass('active');
    }else{
      $(this).addClass('active');
    }
  });
});